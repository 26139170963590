<template>
    <div class="bg-info card m-2">
        <div class="count-text fs-1 mb-2">{{ title }}</div>
        <div class="text-primary font-weight-bold fs-5">{{ count }}</div>
    </div>
</template>

<script>
export default {
    name  : 'AppInfoCard',
    props : {
        title : {
            type    : String,
            default : ''
        },
        count : {
            type : Number
        }
    }
};
</script>

<style scoped>
.count-text {
    color: var(--text-1);
    font-weight: 430;
}
</style>
