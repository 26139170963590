<template>
    <div class="pt-3 pb-2 text-fonts">
        <h5 class="font-weight-bold heading-style-1 ml-2 mb-3">{{ heading }}</h5>
        <div class="fl-x">
            <div class="mr-3">
                <h4 class="text-primary font-weight-bold">{{ firstValue }}</h4>
                <p>{{ firstLabel }}</p>
            </div>
            <div>
                <h4 class="text-primary font-weight-bold">{{ secondValue }}</h4>
                <p>{{ secondLabel }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'AppShortStats',
    props : {
        heading : {
            type    : String,
            default : ''
        },
        firstValue : {
            type    : String,
            default : ''
        },
        secondValue : {
            type    : String,
            default : ''
        },
        firstLabel : {
            type    : String,
            default : ''
        },
        secondLabel : {
            type    : String,
            default : ''
        }
    }
};
</script>

<style scoped lang="scss">
.text-fonts{
    p{
        font-weight:500;
    }
}
</style>
