<template>
    <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data" ref="table" title="Items"
                         :fields="fields" :data="data">
        <template #priority="{rowData}">
            <div class="btn-group-tight">
                <btn v-if="rowData.priority === '1'" color="primary" class="btn-basic-b" disabled size="xs"  text="priority"/>
                <btn v-if="rowData.priority === '2'" color="danger" class="btn-basic-b" disabled size="xs"  text="priority"/>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" size="xs" @click="view(rowData)" text="view"/>
            </div>
        </template>
    </custom-simple-table>
</template>

<script>
export default {
    name : 'modules',
    data () {
        return {
            DataLoading : false,
            data        : [
                {
                    test_cases : 'Module 1',
                    priority   : '1'
                },
                {
                    test_cases : 'Module 2',
                    priority   : '2'
                }
            ],
            fields : [
                {
                    name  : 'test_cases',
                    title : 'Test Cases'
                },
                {
                    name  : '__slot:priority',
                    title : 'Priority'
                },
                {
                    name  : '__slot:actions',
                    title : ''
                }
            ]
        };
    },
    methods : {
        view (item) {

        }
    }
};
</script>

<style scoped>

</style>
