<template>
    <div>
        <app-breadcrumb :path="$route.fullPath"></app-breadcrumb>
        <div class="p-3 pt-6">
            <vue-table-card class="c-vue-table-card table-bordered"
                            title="Modules"
                            :show-search-box="false"
                            :show-pagination="false"
                            :per-page="false"
                            :page-sizes="0"
                            :extra-params="false"
                            url="https://vuetable.ratiw.net/api/users"
                            :fields="fields">
                <template #buttons>
                    <div class="btn-group pt-4 pr-3">
                        <btn icon="fa fa-filter" design="basic-b" text="Filter"></btn>
                        <btn icon="fa fa-plus" text="Add Module" class="c-gradient-1 b-none"></btn>
                    </div>
                </template>
                <template slot="actions" slot-scope="">
                    <btn text="View" icon="fa fa-chevron-right" size="xs" icon-alignment="right" design="basic-b"
                         class="c-lego-btn-xs"></btn>
                </template>
            </vue-table-card>
        </div>
    </div>

</template>

<script>
import AppBreadcrumb from '@components/ui/AppBreadcrumb';

export default {
    name       : 'ProjectModuleFullView',
    components : { AppBreadcrumb },
    data () {
        return {
            fields : [
                {
                    name  : 'module',
                    title : 'Module'
                },
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'admin_user',
                    title : 'Admin User'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
