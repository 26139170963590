import { render, staticRenderFns } from "./ProjectModuleFullView.vue?vue&type=template&id=1952af6c&scoped=true&"
import script from "./ProjectModuleFullView.vue?vue&type=script&lang=js&"
export * from "./ProjectModuleFullView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1952af6c",
  null
  
)

export default component.exports