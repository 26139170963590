<template>
    <div class="bg-8 fl-x c-breadcrumb">
        <div @click="$router.go(-1)" class="font-weight-bold fs--1 bg-6 px-4 c-pointer h-100 py-3"><i
            class="fa fa-chevron-left font-weight-medium mr-2"></i>Back
        </div>
        <div class="px-3 fl-x fl-a-c">{{ path }}</div>
    </div>
</template>

<script>
export default {
    name  : 'AppBreadcrumb',
    props : {
        path : {
            type    : String,
            default : '/'
        }
    }
};
</script>

<style scoped>

</style>
