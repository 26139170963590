<template>
    <div class="col-2 card-container">
        <div class="fl-x fl-a-c py-2">
            <img :src="image" class="img-fluid border-r-1 min-h-7 mb-2" alt="">
            <div class="cardHoverContent"></div>
            <div class="cardDetails fl-x fl-a-s fl-j-c">
                <span><i class="fa fa-eye"></i></span>
                <a href="#" class="text-decoration-none m-0">View</a>
            </div>
        </div>
        <p class="text-center">{{ title }}</p>

    </div>
</template>

<script>
export default {
    name  : 'ProjectWireframeCard',
    props : {
        title : {
            type    : String,
            default : ''
        },
        image : {
            type    : Image,
            default : null
        }
    }
};
</script>

<style scoped lang="scss">

.card-container {
    position: relative;
    cursor: pointer;

    .cardHoverContent {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 80%;
        //background: rgba(184, 152, 65, 0.7);
        background: rgba(0, 0, 0, 0.7);
        border-radius: var(--round-1);
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out 600ms;
        //-moz-transition: all ease 400ms;
        //-webkit-transition: all ease 400ms;
    }

    .cardDetails {
        //width: 222px;
        //height: 101px;
        width: 100%;
        height: auto;
        //background: #2c3da7;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 20%;
        margin: 0 auto;
        text-align: center;
        line-height: 0.8;
        //padding: 32px 0;
        opacity: 0;
        visibility: hidden;
        transition: all ease 700ms;
        -moz-transition: all ease 700ms;
        -webkit-transition: all ease 700ms;
        -ms-transition: all ease 700ms;
        -o-transition: all ease 700ms;

        a {
            color: #FFF;
            display: inline-block;
            //text-transform: uppercase;
            //font-weight: 700;
            font-size: 16px;
            //letter-spacing: 7px;
            //line-height: 0.8;
            margin-bottom: 15px;
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 0.8;
            text-transform: uppercase;
            font-weight: 400;
            color: #FFF;
            letter-spacing: 6px;
        }
    }

    &:hover {
        .cardHoverContent {
            opacity: 1;
            visibility: visible;
        }

        .cardDetails {
            opacity: 1;
            visibility: visible;
            //bottom: -13px;
        }
    }
}
.min-h-7{
    min-height: 7.2rem;
}
</style>
