<template>
    <header class="px-4 pt-4 bg-6">
        <div class="fl-te-c c-border-blue pb-3">
            <div class="fl-x fl-a-c">
                <h5 class="mr-3 font-weight-bold m-0">{{ projectName }}</h5>
                <btn text="Webapp" size="sm" class="c-gradient-red b-none px-4"></btn>
            </div>
            <btn color="danger" size="sm" text="! Report" class="px-4"></btn>
        </div>
        <div class="row m-0">
            <div class="col-3">
                <app-short-stats heading="Overview" first-value="94" first-label="Total TestCases" second-value="91"
                                 second-label="Total TestCases">
                </app-short-stats>
            </div>
            <div class="col-6">
                <div class="fl-x fl-j-c w-100">
                    <members-showcase heading="Members" @view-all-members="$emit('view-all-members')"></members-showcase>
                </div>
            </div>
            <div class="col-3">
                <app-short-stats heading="Timeline" first-value="12 May 22" first-label="Deadline"
                                 second-value="07 Apr 22"
                                 second-label="Created on">
                </app-short-stats>
            </div>
        </div>
        <!--        Overview-->

    </header>
</template>

<script>
import AppShortStats from '@components/ui/AppShortStats';
import MembersShowcase from '@/views/admin/projects/components/MembersShowcase';

export default {
    name       : 'ProjectHeader',
    components : { MembersShowcase, AppShortStats },
    props      : {
        projectName : {
            type    : String,
            default : ''
        }
    }
};
</script>

<style scoped>
.c-border-blue {
    top: 2.0625rem !important;
    border-bottom: 2px solid #cee8ff !important;
}
</style>
