<template>
    <s-form key="'update" role="form" @submit="changePassword"
            class="card container w-40r mt-6 bs-4" ref="form">
        <div class="text-center">
            <h3 class="text-center text-primary mb-4">Update Password</h3>
        </div>
        <validated-input label="Enter Old Password" type="password" name="old_password"
                         v-model="model.old_password"
                         :disabled="loading" :rules="rules.old_password"/>
        <validated-input label="Enter New Password" name="new_password" type="password"
                         v-model="model.new_password"
                         :disabled="loading" :rules="rules.old_password"/>
        <validated-input label="Confirm New Password" type="password" name="confirm_password"
                         v-model="model.confirm_password"
                         :disabled="loading" :rules="rules.confirm_password"/>
        <btn color="primary" class="mt-3 btn-block" text="Update Password" :disabled="loading"/>
    </s-form>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name : 'UpdatePassword',
    data () {
        return {
            loading : false,
            model   : {
                old_password     : '',
                new_password     : '',
                confirm_password : ''
            },
            rules : {
                old_password : {
                    required : true
                },
                new_password : {
                    required : true
                },
                confirm_password : {
                    required : true
                }
            }
        };
    },
    methods : {
        async changePassword () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.change_password, that.model);
            const json = response.data;
            if (json.success === true) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$router.push('/logout/');
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type : 'danger'
            });
        }
    }
};
</script>

<style scoped>
.logo-img {
    width: 16.75rem;
}
</style>
