<template>
    <vue-table-card class="c-vue-table-card c-heading table-bordered "
                    title="Retest Cases"
                    :show-search-box="false"
                    :show-pagination="false"
                    :per-page="false"
                    :page-sizes="0"
                    :extra-params="false"
                    url="https://vuetable.ratiw.net/api/users"
                    :fields="fields">
        <template #footer>
            <btn text="View All" icon-alignment="right" size="md" icon="fa fa-chevron-right" class="lego-icon-btn-sm c-gradient-1 b-none px-4"></btn>
        </template>
        <template slot="actions" slot-scope="">
            <btn  icon="fa fa-eye" size="xs"></btn>
        </template>
    </vue-table-card>
</template>

<script>
export default {
    name : 'ProjectRetestCases',
    data () {
        return {
            fields : [
                {
                    name  : 'test_cases',
                    title : 'Test cases'
                },
                {
                    name  : 'name',
                    title : 'Tested By'
                },
                {
                    name  : 'no_tries',
                    title : 'No. of Tries'
                },
                {
                    name  : 'last_test_date',
                    title : 'Last Test Date'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
