<template>
    <div class="card card-border round-1 mb-4 bs-3">
        <div class="fl-te-b">
            <div class="text-capitalize">
                <h5 class="m-0 heading-style fs-2 font-weight-semibold">Project name</h5>
                <div class="small text-muted mb-1">Backend portal</div>
                <div class="bg-4 round-1 small text-danger w-5r text-center mb-1">Priority</div>
                <h5 class="m-0 fs-2 font-weight-semibold">74</h5>
                <div class="fs--1 font-weight-medium">Test Cases</div>
            </div>
            <PercentageCircle size="large" :percent="74" active-color="primary" complete-color="primary"
                              :animate="true" :dark-mode="false"/>
        </div>
    </div>
</template>

<script>
export default {
    name : 'project-card'
};
</script>

<style scoped>
.card-border {
    border-style: solid;
    border-width: 2px;
    /*border-color: #80808036;*/
    border-color: var(--bg-3);
}
</style>
