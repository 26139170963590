<template>
    <vue-table-card class="c-vue-table-card c-heading table-bordered"
                    title="Test Cases and Modules"
                    :show-search-box="false"
                    :show-pagination="false"
                    :per-page="false"
                    :page-sizes="0"
                    :extra-params="false"
                    url="https://vuetable.ratiw.net/api/users"
                    :fields="fields"
                    :show-border="true"
                    active-shadow="bs-4"
        >
        <template #footer>
            <btn text="View All" icon-alignment="right" @click="$router.push({ name: 'TestcasesFullView'})" size="md" icon="fa fa-chevron-right" class="lego-icon-btn-sm c-gradient-1 b-none px-4"></btn>
        </template>
        <template slot="actions" slot-scope="">
            <btn text="View" icon="fa fa-chevron-right" size="xs" icon-alignment="right" design="basic-b" class="c-lego-btn-xs"></btn>
        </template>
    </vue-table-card>
<!--                        :fields="['name', 'nickname', 'email', 'gender']">-->

</template>

<script>
export default {
    name : 'ProjectTestCases',
    data () {
        return {
            fields : [
                {
                    name  : 'module',
                    title : 'Module'
                },
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'admin_user',
                    title : 'Admin User'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
