<template>
    <div>
        <div class="row m-0 bg-6 p-3">
            <div class="col-12">
                <h5 class="heading-style-1 mb-3">Add Member</h5>
            </div>
            <div class="col-4">
                <v-select class="mb-3 b-1"
                          :options="[{title:'Matthew', icon:'fa fa-eye'}, {title:'Sasi', icon:'fa fa-eye'},{title:'Ajmal', icon:'fa fa-eye'},{title:'Pollard', icon:'fa fa-eye'}]"
                          label="title">
                    <template v-slot:option="option">
                        <!--                <span :class="option.icon"></span>-->
                        <div class="fl-x fl-a-c">
                            <span class="avatar-container mr-2"><img :src="memberImg" alt="" class="img-fluid"></span>
                            <span>{{ option.title }}</span>
                        </div>

                    </template>
                </v-select>
            </div>
            <div class="col-4">
                <validated-select placeholder="Select Role"></validated-select>
            </div>
            <div class="col-2">
                <btn text="Clear" class="c-gradient-gray b-none px-5"></btn>
            </div>
            <div class="col-2">
                <btn text="Add Member" icon="fa fa-user" class="b-none c-gradient-1"></btn>
            </div>
        </div>

        <div class="p-4">
            <!--        <validated-ajax-vue-select class="col-6" name="Payee" :rules="{required: true}" :url="PayeeOptions"-->
            <!--                                   label="Payee" :disabled="loading" v-model="model.payee_m"/>        -->
            <!--        <validated-ajax-vue-select class="" name="Member" :rules="{required: true}"  :url="testOptions"-->
            <!--                                   label="Member" :disabled="false"/>-->
            <!--        <validated-select class="" ref="userSelect" @blur="searchUsers" name="Member" :rules="{required: true}"-->
            <!--                         :options="['Matthew', 'Sasi', 'Ajmal', 'Pollard']" label="Member" :disabled="false">-->
            <!--        </validated-select>-->

            <h5 class="heading-style-1 mb-3">Managed by</h5>
            <div class="row">
                <div class="col-6">
                    <app-member-card>
                        <template #role>
                            <div class="p-1 px-2 border-r-1 bg-primary">
                                Owner
                            </div>
                        </template>
                    </app-member-card>
                </div>
                <div class="col-6">
                    <app-member-card>
                        <template #buttons>
                            <btn text="remove" size="sm" color="danger"></btn>
                        </template>
                    </app-member-card>
                </div>
                <div class="col-12 mt-3">
                    <h5 class="heading-style-1 mb-3">Developers</h5>
                </div>
                <div class="col-6 mt-4">
                    <app-member-card>
                        <template #buttons>
                            <btn text="remove" size="sm" color="danger"></btn>
                        </template>
                    </app-member-card>
                </div>
                <div class="col-6 mt-4">
                    <app-member-card>
                        <template #buttons>
                            <btn text="remove" size="sm" color="danger"></btn>
                        </template>
                    </app-member-card>
                </div>
                <div class="col-6 mt-4">
                    <app-member-card>
                        <template #buttons>
                            <btn text="remove" size="sm" color="danger"></btn>
                        </template>
                    </app-member-card>
                </div>
                <div class="col-6 mt-4">
                    <app-member-card>
                        <template #buttons>
                            <btn text="remove" size="sm" color="danger"></btn>
                        </template>
                    </app-member-card>
                </div>
                <div class="col-12 mt-3">
                    <h5 class="heading-style-1 mb-3">Testers</h5>
                </div>
                <div class="col-6 mt-4">
                    <app-member-card>
                        <template #buttons>
                            <btn text="remove" size="sm" color="danger"></btn>
                        </template>
                    </app-member-card>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AppMemberCard from '@/views/admin/projects/components/AppMemberCard';
import urls from '@/data/urls';
import memberImg from '../../../../assets/img/members/member-3.jpg';

export default {
    name       : 'MembersManagement',
    components : { AppMemberCard },
    data () {
        return {
            testOptions : urls.testOptions,
            memberImg   : memberImg
        };
    }

};
</script>

<style scoped lang="scss">
.avatar-container {
    img {
        width: 3.55rem;
        border-radius: 50% !important;
        border: 2px solid white;
    }
}
</style>
