<template>
    <div class="fl-x fl-a-c w">
        <span class="mr-2 font-weight-medium">0.1</span>
        <span class="avatar-container mr-2">
         <img :src="memberImg" alt="" class="img-fluid">
     </span>
        <span class="mr-2 font-weight-medium">Anto</span>
        <span :class="{'role-container':$slots.role,'button-container':$slots.buttons}">
            <slot name="role"></slot>
            <slot name="buttons"></slot>
     </span>
<!--        <span class="button-container">-->
<!--        </span>-->
    </div>
</template>

<script>
import memberImg from '../../../../assets/img/members/member-3.jpg';

export default {
    name : 'AppMemberCard',
    data () {
        return {
            memberImg : memberImg
        };
    }
};
</script>

<style scoped lang="scss">
.avatar-container {
    img {
        width: 3.55rem;
        border-radius: 50% !important;
        border: 2px solid white;
    }
}

.button-container {
    //width: 100%;
    //height: 100%;
    display: flex;
    //align-items: center;
    justify-content: flex-end;
    margin-left: var(--spacer-6);
}

.role-container {
    //width: 100%;
    //height: 100%;
    display: flex;
    //align-items: center;
    justify-content: flex-start;
}

//.role-container{
//    width: 100%;
//}
</style>
