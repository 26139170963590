<template>
    <div class="text-center p-0" style="width: 80px" :class="{'c-success':priority==='low','c-warning':priority==='medium', 'c-danger':priority==='high'}">
        <p class="m-0">{{priority.charAt(0).toUpperCase() + priority.slice(1)}}</p>
    </div>
</template>

<script>
export default {
    name  : 'AppPriorityTag',
    props : {
        color : {
            type    : String,
            default : 'primary'
        },
        priority : {
            type    : String,
            default : 'priority'
        }
    }
};
</script>

<style scoped>
    .c-primary{
        color: var(--color-primary);
        border-radius: var(--round-1);
        border: 1px solid var(--color-primary);
    }
    .c-danger{
        color: var(--color-danger);
        border-radius: var(--round-1);
        border: 1px solid var(--color-danger);
    }
    .c-success{
        color: var(--color-success);
        border-radius: var(--round-1);
        border: 1px solid var(--color-success);
    }
    .c-warning{
        color: var(--color-warning);
        border-radius: var(--round-1);
        border: 1px solid var(--color-warning);
    }
</style>
