<template>
    <div class="m-0 px-3">
        <count-section class="mb-4"/>
        <our-projects-list/>
    </div>
</template>

<script>
import CountSection from './components/count-section';
import OurProjectsList from './components/our-projects-list';

export default {
    name       : 'dashboard',
    components : { OurProjectsList, CountSection }
};
</script>

<style scoped>

</style>
