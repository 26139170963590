<template>
    <div class="member-items pt-3 pb-2">
        <h5 class="font-weight-bold heading-style-1 ml-2 mb-3">{{ heading }}</h5>
        <div>
            <span><img :src="memberImg1" alt="" class="img-fluid"></span>
            <span><img :src="memberImg2" alt="" class="img-fluid trans-l-1"></span>
            <span><img :src="memberImg3" alt="" class="img-fluid trans-l-2"></span>
            <span><img :src="memberImg4" alt="" class="img-fluid trans-l-3"></span>
            <span class="bg-primary trans-l-4 extra-count">+4</span>
            <span>
            <btn text="View All" @click="$emit('view-all-members')" size="sm" icon="fa fa-chevron-right" icon-alignment="right"></btn>
        </span>
        </div>
    </div>
</template>

<script>
import memberImg1 from '../../../../assets/img/members/member-1.png';
import memberImg2 from '../../../../assets/img/members/member-2.png';
import memberImg3 from '../../../../assets/img/members/member-3.jpg';
import memberImg4 from '../../../../assets/img/members/member-5.jpg';

export default {
    name  : 'MembersShowcase',
    props : {
        heading : {
            type    : String,
            default : ''
        }
    },
    data () {
        return {
            memberImg1 : memberImg1,
            memberImg2 : memberImg2,
            memberImg3 : memberImg3,
            memberImg4 : memberImg4
        };
    }
};
</script>

<style scoped lang="scss">
.member-items {
    div {
        display: flex;
        align-items: center;

        span {
            width: 3.55rem !important;
            border-radius: 50%;
            //border: 2px solid white;

            img {
                width: 3.55rem;
                border-radius: 50% !important;
                border: 2px solid white;
            }
        }
    }

}

.extra-count {
    padding: 15px;
}

.trans-l-1 {
    transform: translateX(-4px);
}

.trans-l-2 {
    transform: translateX(-8px);
}

.trans-l-3 {
    transform: translateX(-12px);
}

.trans-l-4 {
    transform: translateX(-16px);
}
</style>
