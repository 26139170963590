<template>
    <div class="">
        <div class="row m-0">
            <div class="col-12">
                <div class="fl-x-b mb-4">
                    <h5>Our Projects</h5>
                    <validated-vue-select class="mb-0 ml-4 w-15r" size="sm" :options="statusOptions"
                                          name="status" placeholder="Status Filter" v-model="status" select-first/>
                </div>
            </div>
            <div v-for="i in 10" v-bind:key="i" class="col-3">
                <project-card/>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectCard from './project-card';

export default {
    name       : 'our-projects-list',
    components : { ProjectCard },
    data () {
        return {
            statusOptions : [
                { value : 'Ongoing Project', label : 'Ongoing Projects' },
                { value : 'Completed Project', label : 'Completed Projects' },
                { value : 'Dropped Project', label : 'Dropped Projects' }
            ],
            status : ''
        };
    }
};
</script>

<style scoped>

</style>
