<template>
    <div class="m-0">
        <div class="row text-center m-0">
            <app-info-card class="col" title="Total Projects" :count="126"></app-info-card>
            <app-info-card class="col" title="Ongoing Projects" :count="12"></app-info-card>
            <app-info-card class="col" title="Completed Projects" :count="12"></app-info-card>
            <div @click="$refs.addModal.show()" class="col c-gradient-1 c-pointer card m-2">
                <span class="text-white"><i class="fa fa-plus"></i></span><br/>
                <span class="text-white">Create New Projects</span>
            </div>
        </div>
        <custom-modal no-close-on-backdrop title="New Project" ref="addModal" class="modal-md" width="35r"
                      header-color="">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" class="form-label-bold form-input-border">
                <div class="row">
                    <div class="col">
                        <validated-input class="form-label-bold" label="Name" v-model="model.name"
                                         :rules="{required: true}"
                                         :disabled="loading"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-date-picker label="Deadline" v-model="model.deadline" :rules="{required: true}"
                                               :disabled="loading"/>
                    </div>
                    <div class="col">
                        <validated-vue-select label="Project Type" v-model="model.type" :rules="{required: true}"
                                              :disabled="loading" :options="typeOptions"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <validated-textarea class="form-textarea-xl" label="Description" name="description"
                                            v-model="model.description" :rules="model.description"/>
                    </div>
                </div>
                <div class="fl-eqh-c mt-2 mb-2">
                    <div class="btn-group">
                        <btn size="sm" class="w-10r c-gradient-gray b-none" text="Cancel" :disabled="loading" :loading="loading"
                             loading-text="Processing.."/>
                        <btn class="w-10r c-gradient-1 b-none" text="Submit" :disabled="loading" :loading="loading"
                             loading-text="Processing.."/>
                    </div>
                </div>
            </b-form>
        </custom-modal>
    </div>
</template>

<script>
import AppInfoCard from '@components/ui/AppInfoCard';

export default {
    name       : 'count-section',
    components : { AppInfoCard },
    data () {
        return {
            model       : '',
            addUrl      : '',
            typeOptions : [
                { value : 'Ongoing Project', label : 'Ongoing Projects' },
                { value : 'Completed Project', label : 'Completed Projects' },
                { value : 'Dropped Project', label : 'Dropped Projects' }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
        }
    }
};
</script>

<style scoped>

</style>
