<template>
    <div class="p-3">
        <div class="row">
            <project-wireframe-card v-for="(item,i) in items"
                                    :key="i"
                                    :title="item.title"
                                    :image="item.img"
            ></project-wireframe-card>
            <div class="col-2">
                <div class="upload-btn-dashed">
                    <i class="fa fa-2x fa-cloud-upload m-0"></i>
                    <p class="m-0">Upload Image</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import sampleWireframe from '../../../../assets/img/projects/wireframe.jpg';
import sampleWireframe1 from '../../../../assets/img/projects/wireframe-1.jpg';
import sampleWireframe2 from '../../../../assets/img/projects/wireframe-2.jpg';
import ProjectWireframeCard from '@/views/admin/projects/components/ProjectWireframeCard';

export default {
    name       : 'ProjectWireframes',
    components : { ProjectWireframeCard },
    data () {
        return {
            items : [
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe
                },
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe1
                },
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe2
                },
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe1
                },
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe
                },
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe2
                },
                {
                    title : 'Project wireframe',
                    img   : sampleWireframe
                }

            ]
        };
    }

};
</script>

<style scoped>
.upload-btn-dashed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:var(--color-primary);
    width: 100%;
    height: 100%;
    border: 2px dashed var(--color-primary);
    border-radius: var(--round-1);
    cursor: pointer;
}
</style>
