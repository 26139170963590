<template>
    <div>
        <app-breadcrumb :path="$route.fullPath"></app-breadcrumb>
        <div class="p-3 pt-6">
            <div class="fl-te-c p-4">
                <h4 class="heading-style-1 font-weight-bold">Scenario 2</h4>
                <div class="btn-group">
                    <btn text="Remove Scenario" class="c-gradient-red b-none" icon="fa fa-trash-o"></btn>
                    <btn icon="fa fa-filter" design="basic-b" text="Filter" class="px-4"></btn>
                    <btn icon="fa fa-plus" text="Add Testcase" class="c-gradient-1 b-none"></btn>
                </div>
            </div>
            <vue-table-card class="c-vue-table-card b-1 bs-3"
                            title="Pending testcases"
                            :show-search-box="false"
                            :show-pagination="false"
                            :per-page="false"
                            :page-sizes="0"
                            :extra-params="false"
                            url="https://vuetable.ratiw.net/api/users"
                            :fields="fields"
                            :show-border="true"
                            active-shadow="bs-4">
                <template slot="priority">
                    <app-priority-tag priority="low"></app-priority-tag>
                </template>
                <template slot="actions" slot-scope="">
                    <btn text="View" icon="fa fa-chevron-right" size="xs" icon-alignment="right" design="basic-b"
                         class="c-lego-btn-xs"></btn>
                </template>
            </vue-table-card>
            <vue-table-card class="c-vue-table-card b-1 bs-3"
                            title="Completed testcases"
                            :show-search-box="false"
                            :show-pagination="false"
                            :per-page="false"
                            :page-sizes="0"
                            :extra-params="false"
                            url="https://vuetable.ratiw.net/api/users"
                            :fields="fields"
                            :show-border="true"
                            active-shadow="bs-4">
                <template slot="priority">
                    <app-priority-tag priority="medium"></app-priority-tag>
                </template>
                <template slot="actions" slot-scope="">
                    <btn text="View" icon="fa fa-chevron-right" size="xs" icon-alignment="right" design="basic-b"
                         class="c-lego-btn-xs"></btn>
                </template>
            </vue-table-card>
        </div>
    </div>
</template>

<script>
import AppBreadcrumb from '@components/ui/AppBreadcrumb';
import AppPriorityTag from '@components/ui/AppPriorityTag';

export default {
    name       : 'ProjectTestCasesFullView',
    components : { AppPriorityTag, AppBreadcrumb },
    data () {
        return {
            fields : [
                {
                    name  : 'module',
                    title : 'Testcase'
                },
                {
                    name  : '__slot:priority',
                    title : 'Priority'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
