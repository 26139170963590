<template>
    <div>
        <project-header @view-all-members="openMembersModal" project-name="Minibells"></project-header>
        <tabs class="header-bg-light">
            <tab title="Description">
                <project-description></project-description>
            </tab>
            <tab title="Wireframes">
                <project-wireframes></project-wireframes>
            </tab>
            <tab title="Testcases">
                <project-test-cases></project-test-cases>
                <project-retest-cases></project-retest-cases>
            </tab>
        </tabs>
        <custom-modal no-close-on-backdrop title="Members" ref="membersModal" class="p-0" width="60r"
                      header-color="">
            <members-management></members-management>
        </custom-modal>
    </div>
</template>

<script>
import ProjectHeader from '@/views/admin/projects/components/ProjectHeader';
import ProjectDescription from '@/views/admin/projects/components/ProjectDescription';
import ProjectWireframes from '@/views/admin/projects/components/ProjectWireframes';
import ProjectTestCases from '@/views/admin/projects/components/ProjectTestCases';
import ProjectRetestCases from '@/views/admin/projects/components/ProjectRetestCases';
import MembersManagement from '@/views/admin/projects/components/MembersManagement';

export default {
    name       : 'ProjectPage',
    components : { MembersManagement, ProjectRetestCases, ProjectTestCases, ProjectWireframes, ProjectDescription, ProjectHeader },
    methods    : {
        openMembersModal () {
            this.$refs.membersModal.show();
        }
    }
};
</script>

<style scoped lang="scss">

</style>
